import React, { useRef, useState } from "react"
import classNames from "classnames"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Text, Image, GeneralHours } from "../components/Core"
import { Button, ButtonGroup } from "../components/Button"
import SizzleMp4 from "../videos/sizzle_desktop.mp4"
import { Heading } from "../components/Hero"

import PlayPause from "../components/Sizzle/playPause"
import ButtonGroupMap from "../components/Button/ButtonGroupMap"

import Seamless from "../components/Seamless"
import Carousel from "../components/carousel/comFeaturedCarousel"
import HorizontalTwoColumns from "../components/Bulma/HorizontalTwoColumns"
import AllRatingsReviews from "../components/SocialReviews/AllSocialReviews"
import LocationInfo from "../components/LocationInfo"
import { TestimonialPreview } from "../components/Cards"
import LearnMoreCards from "./index/LearnMoreCards"
import Sizzle from "./index/Sizzle"
import StaticHeading from "./index/StaticHeading"
import StaticHero from "./index/StaticHero"

import useForms from "../hooks/useForms"
import { Section, Columns } from "../components/Containers"
import TestimonialGrid from "../components/Cards/TestimonialGrid"

const SingleLocation = ({ data, pageContext, location }) => {
  const napRef = useRef()
  const post = data.allLocationsJson.nodes[0]
  const { metaDescription, metaTitle, language } = pageContext

  const hasReviews = data.hasReviews.nodes[0].reviews
  let allReviews = data.allReviews.nodes
  const homepageData = data.homeData.nodes[0]

  const { form } = useForms(
    post.pageData.layout_3.formSection.seamlessShortcode
  )

  const thisLocation = post

  // GET RELATED LOCATIONS
  // Add locationNames in CMS or JSON to relatedLocations array
  //  [
  //    "location name"
  //  ]
  let { relatedLocations } = data.allLocationsJson.nodes[0].pageData.layout_3
  const allLocations = data.allLocationsDetails.nodes

  // search all locations for matching locationNames
  let locationRelations = allLocations.filter(l =>
    relatedLocations.includes(l.pageData.title)
  )

  // just making cleaner objects here
  locationRelations = locationRelations.map(l => l.locationData)
  // GET RELATED LOCATIONS

  const { pageData } = thisLocation
  const { locationData } = thisLocation

  let schemaData
  if (language === "en") {
    schemaData = {
      presets: pageData.schemas.presets,
      customSchema: pageData.schemas.custom,
      dateModified: pageContext.dateModified,
      language,
      metaTitle: pageData.metaTitle,
      description: pageData.metaDescription,
      path: location.pathname
    }
  }

  return (
    <Layout language={language}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        schemaData={schemaData}
        lang={language}
      />
      <LayoutThree
        homepageData={homepageData}
        hasReviews={hasReviews}
        reviews={allReviews}
        sizzle={SizzleMp4}
        language={language}
        post={post}
        pageData={pageData}
        locationData={locationData}
        relatedLocations={locationRelations}
        hearWhatTheyHaveToSay={data.hearWhatTheyHaveToSay.nodes[0]}
        formEmbed={form.embedUrl}
        napRef={napRef}
      />
    </Layout>
  )
}

/* Passes layout prop to all children */
function LayoutWrapper(props) {
  return (
    <div className={props.layout}>
      {React.Children.map(props.children, (child, i) => {
        if (child) {
          return React.cloneElement(child, {
            // this properties are available as a props in child components
            layout: props.layout
          })
        }
      })}
    </div>
  )
}

function FeaturedProcedures(props) {
  return (
    <Section className="body-section featured-procedures-section">
      <div className="columns">
        <div className="column is-3" />
        <div className="column has-text-centered-desktop featured-procedures-header">
          {props.featuredProceduresHeading ? (
            <Text
              className="px-40--mobile"
              as="h2"
              text={props.featuredProceduresHeading}
            />
          ) : (
            <h2>Procedures at Our {props.cityName} Office</h2>
          )}
          {props.featuredProceduresBlurb ? (
            <Text
              useStringReplace
              className="mb-0 px-40--mobile"
              text={props.featuredProceduresBlurb}
            />
          ) : (
            <p className="mb-0 px-40--mobile">
              We offer a wide range of oral and maxillofacial surgery
              procedures. To view all of our services, please visit our{" "}
              <a href="/procedure/" title="View all of our procedures">
                oral surgery page
              </a>
              .
            </p>
          )}
        </div>
        <div className="column is-3" />
      </div>
      {props.featuredProcedures.map((item, i) => {
        const featuredProcedure = item

        let colorBack = true

        if (i % 2 === 0 && props.layout === "layout-3") colorBack = false

        const featuredProcedureClasses = classNames(
          "columns is-vcentered featured-procedure joshua-tree-content",
          {
            "reverse-rows": i % 2 === 1 && props.layout === "layout-2"
          }
        )

        return (
          <React.Fragment key={i}>
            <Section
              zeroTopMobile
              colorBack={colorBack}
              className={featuredProcedureClasses}
              key={featuredProcedure.heading}>
              <div className="column is-2" />
              <div className="column">
                <Image publicId={featuredProcedure.imageId} />
              </div>
              <div className="column is-2" />
              <div className="column bottom-col-mobile  px-40--mobile">
                <Text as="h3" text={featuredProcedure.heading} />
                <Text useStringReplace text={featuredProcedure.text} />
                {/* <ButtonGroupMap hyperlink buttons={featuredProcedure.buttons} /> */}
                <FeaturedProcedureButtons
                  buttons={featuredProcedure.buttons}
                  napRef={props.napRef}
                />
              </div>
              <div className="column is-3" />
            </Section>
            {/* <FeaturedProcedureReviews colorBack={colorBack} heading={featuredProcedure.reviewsHeading} reviews={reviews} /> */}
          </React.Fragment>
        )
      })}
    </Section>
  )
}

function FormSection({ form, embed }) {
  const toCamelCase = string => {
    let out = ""
    string.split(" ").forEach((el, idx) => {
      const add = el.toLowerCase()
      out += idx === 0 ? add : add[0].toUpperCase() + add.slice(1)
    })
    return out
  }

  if (form.hasForm) {
    return (
      <div className="columns location-contact-form">
        <div className="column is-4" />

        <div className="column">
          <center>
            <div style={{ maxWidth: "1000px" }}>
              <div id="form">
                {form.heading && <h3>{form.heading}</h3>}
                <p className="required-fields">
                  <span className="required-asterisk">*</span>
                  Required Fields
                </p>
              </div>
              <Seamless src={embed} />
            </div>
          </center>
        </div>

        <div className="column is-4" />
      </div>
    )
  }
  return ""
}

function LayoutThree(props) {
  const data = { ...props }
  const sizzleButtonStyle = {
    position: "absolute",
    top: 0,
    zIndex: 200
  }
  return (
    // <LayoutWrapper layout={data.pageData.layout}>
    <LayoutWrapper layout="layout-3">
      {data.homepageData.hasSizzle ? (
        <Sizzle
          playing={false}
          sizzleButtonStyle={sizzleButtonStyle}
          youtube={data.homepageData.sizzleYoutube}
          language={data.language}>
          <StaticHeading
            youtube={data.homepageData.sizzleYoutube}
            heading={data.pageData.layout_3.sizzleHeadingButtons.heading}
            subheading={data.homepageData.hero.subheading}
            language={data.language}
          />
        </Sizzle>
      ) : (
        <StaticHero
          heading={data.pageData.layout_3.sizzleHeadingButtons.heading}
          subheading={data.homepageData.hero.subheading}
          bgImage={data.homepageData.hero.bgImage}
          language={data.language}
        />
      )}

      {data.language == "en" && (
        <>
          {data.hasReviews && (
            <Section
              colorBack
              zeroTop
              zeroBottom
              className="homepage__testimonial-section">
              <Columns
                sideColumnsSize={0}
                className="homepage__testimonial-wall">
                <div className="column">
                  <Text
                    as="h4"
                    className="has-text-centered mb-2 mt-2--mobile"
                    text="Hear Stories From People Just Like You"
                  />
                  <TestimonialGrid reviews={data.reviews} />
                </div>
              </Columns>
            </Section>
          )}

          {/* <div className="columns">
            {data.homepageData.featuredReviews.map(card => (
              <div key={card.youtube} className="column">
                <TestimonialPreview
                  language={data.language}
                  youtube={card.youtube}
                  image={card.image}
                  heading={card.heading}
                  subheading={card.subHeading}
                />
              </div>
            ))}
          </div> */}
        </>
      )}

      <LearnMoreCards
        language={data.language}
        post={data.homepageData.learnMoreCards}
      />

      <TextImage
        className="is-vcentered text-image"
        text={data.pageData.layout_3.textImage.text}
        // imageId={data.pageData.layout_3.textImage.imageId}
      />

      <FeaturedProcedures
        featuredProcedures={data.pageData.layout_3.featuredProcedures}
        featuredProceduresHeading={
          data.pageData.layout_3.featuredProceduresHeading
        }
        // reviews={props.reviews}
        featuredProceduresBlurb={data.pageData.layout_3.featuredProceduresBlurb}
        napRef={props.napRef}
        cityName={data.locationData.cityName}
      />

      <LocationInfo
        data={data.pageData.layout_3.locationDetails}
        location={data.locationData}
        relatedLocations={data.relatedLocations}
        napRef={props.napRef}
        hideAddress={props.post.useOnContact && props.post.useOnFooter}
      />

      <FormSection
        embed={props.formEmbed}
        form={data.pageData.layout_3.formSection}
      />

      <Section colorBack={!data.pageData.layout_3.formSection.hasForm}>
        <AllRatingsReviews
          heading="Our Ratings and Reviews From Patients"
          // buttonHref="/testimonial/"
          // buttonText="More Reviews"

          office={data.post.isSilo ? false : data.locationData.locationName}
        />
      </Section>
      {/* <CallToAction
        colorBack={!data.pageData.layout_3.formSection.hasForm}
        heading={data.pageData.layout_3.getStarted.heading}
        blurb={data.pageData.layout_3.getStarted.blurb}
        buttons={data.pageData.layout_3.getStarted.buttons}
      /> */}
    </LayoutWrapper>
  )
}

function TextImage(props) {
  if (props.imageId) {
    return (
      <HorizontalTwoColumns
        style={{ paddingTop: "88px" }}
        className={props.className}
        leftColWidth={2}
        middleColWidth={1}
        rightColWidth={2}
        leftColumn={
          <Text
            useStringReplace
            className="column is-9 location-text-image"
            text={props.text}
          />
        }
        rightColumn={
          <div className="column">
            <Image publicId={props.imageId} />
          </div>
        }
      />
    )
  }
  return (
    <Section>
      <div className="columns text-image imageless">
        <div className="column is-2" />
        <div className="column">
          <Text className="px-40--mobile" useStringReplace text={props.text} />
          <ButtonGroup isCentered>
            <Button
              href="/procedure/"
              buttonText="Our Procedures"
              contained
              destination="internal"
            />
          </ButtonGroup>
        </div>
        <div className="column is-2" />
      </div>
    </Section>
  )
}

function FeaturedProcedureButtons({ buttons, napRef }) {
  const scrollToElement = id => {
    // napRef.current.scrollIntoView(true, {behavior: "smooth"})
    window.scroll({
      top: napRef.current.offsetTop - 100,
      left: 0,
      behavior: "smooth"
    })
  }

  return (
    <ButtonGroup>
      {buttons &&
        buttons.map((btn, i) => (
          <React.Fragment key={i}>
            {btn.button.href[0] === "#" ? (
              <a
                className="standard-button contained"
                onClick={() => scrollToElement()}>
                {btn.button.buttonText}
              </a>
            ) : (
              <Button
                contained={btn.button.appearance === "contained"}
                minimal={btn.button.appearance === "minimal"}
                external={btn.button.destination === "external"}
                tel={btn.button.destination === "tel"}
                key={btn.button.href}
                buttonText={btn.button.buttonText}
                href={btn.button.href}
              />
            )}
          </React.Fragment>
        ))}
    </ButtonGroup>
  )
}

export const pageQuery = graphql`
  query SingleLocation($title: String!) {
    allReviews: allReviewsJson(
      filter: { reviewType: { eq: "Patient" }, language: { eq: "en" } }
      limit: 30
    ) {
      nodes {
        ...FacewallData
      }
    }
    hasReviews: allDataJson(filter: { optionsItem: { eq: "build-pages" } }) {
      nodes {
        reviews
      }
    }
    homeData: allUniquePagesJson(filter: { title: { eq: "/" } }) {
      nodes {
        hasSizzle
        sizzleYoutube
        hero {
          heading
          subheading
          bgImage
        }
        featuredReviews {
          heading
          subHeading
          youtube
          image
        }
        learnMoreCards {
          blurb
          hasThisSection
          heading
          cards {
            blurb
            heading
            imageId
            button {
              buttonText
              href
            }
          }
        }
      }
    }
    allLocationsJson(filter: { pageData: { title: { eq: $title } } }) {
      nodes {
        isSilo
        useOnContact
        useOnFooter
        pageData {
          searchHeading
          title
          layout
          schemas {
            presets
            custom
          }
          layout_3 {
            ...layoutThreeFields
            featuredProceduresBlurb
            relatedLocations
            locationDetails {
              heading
              imageId
              listOne
              listTwo
              showAddress
              showEmail
              showPhone
              text
              buttons {
                button {
                  href
                  buttonText
                  appearance
                  destination
                }
              }
            }
            formSection {
              hasForm
              heading
              locationForm
              seamlessShortcode
            }
            getStarted {
              subheading
              heading
              buttonText
              href
              buttons {
                button {
                  appearance
                  destination
                  href
                  buttonText
                }
              }
              blurb
            }
          }
        }
        locationData {
          locationName
          stateAbbr
          googlePlaceLink
          googleMapsIframe
          streetNumber
          streetName
          phone
          cityZip
          cityName
          stateName
          email
          locationHours {
            endTime
            startTime
            weekDay
          }
          coords {
            lng
            lat
          }
          areaServed
        }
      }
    }
    allLocationsDetails: allLocationsJson {
      nodes {
        pageData {
          title
        }
        locationData {
          locationName
          stateAbbr
          googlePlaceLink
          streetNumber
          streetName
          phone
          cityZip
          cityName
          stateName
          email
          locationHours {
            endTime
            startTime
            weekDay
          }
        }
      }
    }

    hearWhatTheyHaveToSay: allUniquePagesJson(
      filter: { hearWhatTheyHaveToSay: { heading: { ne: null } } }
    ) {
      nodes {
        hearWhatTheyHaveToSay {
          heading
          videos {
            patients {
              imageId
              youtube
              caption
            }
            referringProviders {
              imageId
              youtube
              caption
            }
          }
        }
      }
    }
    allUniquePagesJson(filter: { title: { eq: "about-us/" } }) {
      nodes {
        meetOurDoctors {
          images {
            doctor {
              caption
              imageId
              url
            }
          }
        }
      }
    }
  }
`

export default SingleLocation
